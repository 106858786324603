<template>
  <Autocomplete
    v-model="currentValue"
    :placeholder="placeholder"
    :throttle="600"
    :max-results="6"
    :options="list"
    :pending="isPending"
    :no-results="noResults"
    :errors="combinedErrors"
    :disabled="disabled"
    :size="size"
    :theme="theme"
    :with-chevron="withChevron"
    v-bind="$attrs"
    @onSelect="handleSelect"
    @onSearch="handleSearch">
    <template #append="{ results, close }">
      <slot
        v-bind="{ results, close }"
        name="append"></slot>
    </template>
    <template #helper>
      Начните вводить название насленного пункта, минимум 3 символа
    </template>
    <template #no-result>
      Такой населенный пункт не найден, убедитесь в правильности написания
      названия или попробуйте указать название ближайшего к вам города/районного
      центра
    </template>
  </Autocomplete>
</template>

<script>
import Autocomplete from "./Autocomplete";
import { talentRequest } from "@/services/api";
export default {
  name: "AddressInput",
  components: {
    Autocomplete,
  },
  inheritAttrs: false,
  props: {
    value: {},
    errors: {},
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
    },
    theme: {
      type: String,
    },
    withChevron: {
      type: Boolean,
      default: false,
    },
    searchOptions: {
      type: Object,
      default() {
        return {};
      },
    },
    searchEndpoint: {
      type: String,
      default: "/api/geo/suggest/",
    },
    listMapper: {
      type: Function,
      default: (n) => {
        return {
          label: n.unrestricted_value,
          value: n.unrestricted_value,
        };
      },
    },
  },
  data() {
    return {
      currentValue: this.value,
      list: [],
      isPending: false,
      noResults: false,
      searchErrors: [],
    };
  },
  computed: {
    combinedErrors() {
      const { errors, searchErrors } = this;
      if (searchErrors.length) return searchErrors;
      return errors;
    },
  },
  watch: {
    value() {
      this.currentValue = this.value;
    },
  },
  methods: {
    handleSelect(data) {
      if (data && typeof data === "object" && data.value) {
        this.$emit("input", data.value);
        return;
      }
      this.$emit("input", "");
    },
    async handleSearch(query) {
      this.isPending = true;
      this.searchErrors = [];
      const { searchOptions } = this;
      try {
        const response = await talentRequest({
          method: "POST",
          url: this.searchEndpoint,
          data: {
            query,
            ...searchOptions,
          },
        });

        const list = response.data
          .filter((n) => n.data.country_iso_code !== "UA")
          .map(this.listMapper);
        this.list = list;
        this.noResults = list.length === 0;
      } catch (error) {
        this.searchErrors = [
          "Произошла ошибка поиска города. Пожалуйста, сообщите нам об этой ошибке.",
        ];
      }
      this.isPending = false;
    },
  },
};
</script>
