<template>
  <div
    class="autocomplete"
    :class="[`autocomplete--theme-${theme}`]"
    @blur="handleBlurContent">
    <BaseInput
      v-model="searchValue"
      v-bind="$attrs"
      :errors="errors"
      :theme="theme"
      type="text"
      autocomplete="new-password"
      class="autocomplete__input"
      v-on="listeners"
      @input="handleInput"
      @focus="handleFocus"
      @keydown.enter.prevent="handleSelectFirstResult">
      <template
        v-if="withChevron"
        #append>
        <Transition name="fade-in">
          <button
            type="button"
            title="Очистить"
            class="autocomplete__clear"
            @click.prevent="handleClick">
            <span
              :class="{
                'is-open': isOpen && !searchValue,
                selected: searchValue,
                'icon-disabled': disabled,
              }"
              class="autocomplete__select" />
          </button>
        </Transition>
        <Transition name="fade-in">
          <BaseSpinner
            v-show="pending"
            :class="{ 'with-chevron': withChevron }"
            class="autocomplete__spinner" />
        </Transition>
      </template>
      <template
        v-else
        #append>
        <Transition name="fade-in">
          <button
            v-show="searchValue"
            type="button"
            title="Очистить"
            class="autocomplete__clear"
            @click.prevent="handleClear">
            <BaseIcon
              class="autocomplete__clear-icon"
              glyph="close" />
          </button>
        </Transition>
        <Transition name="fade-in">
          <BaseSpinner
            v-show="pending"
            brand
            class="autocomplete__spinner" />
        </Transition>
      </template>
    </BaseInput>
    <div
      v-show="isOpen"
      class="autocomplete__content"
      tabindex="-1"
      :style="{ maxHeight: maxHeight }">
      <div
        v-if="results.length"
        class="autocomplete__list">
        <div
          v-for="(item, idx) in results"
          :key="idx"
          class="autocomplete__item"
          tabindex="0"
          @click="setResult(item)"
          @keyup.enter="setResult(item)">
          <slot
            v-bind="{ item }"
            name="option">
            {{ item.label }}
          </slot>
        </div>
      </div>

      <div v-show="hasNoResults">
        <div class="autocomplete__text">
          <slot name="no-result">
            <span>Ничего не найдено</span>
          </slot>
        </div>
      </div>

      <div v-show="showHelper">
        <div class="autocomplete__text">
          <slot name="helper">
            <span>Начните вводить название...</span>
          </slot>
        </div>
      </div>

      <div class="autocomplete__append">
        <slot
          v-bind="{ results, close }"
          name="append"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
const MIN_SEARCH_LENGTH = 3;
export default {
  name: "AutoComplete",
  inheritAttrs: false,
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {},
    maxResult: {
      type: Number,
      default: 5,
    },
    minSearch: {
      type: Number,
      default: MIN_SEARCH_LENGTH,
    },
    /**
     * Задержка вызова метода поиска, в мс
     */
    throttle: {
      type: Number,
      default: 600,
    },
    options: {
      type: Array,
    },
    pending: {
      type: Boolean,
      default: false,
    },
    errors: {},
    theme: {
      type: String,
    },
    withChevron: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // максимальная высота выпадашки
    maxHeight: {
      type: String,
      default: "400px",
    },
  },
  data() {
    return {
      searchValue: this.value || "",
      isOpen: false,
    };
  },
  computed: {
    listeners() {
      // eslint-disable-next-line no-unused-vars
      const { input, ...rest } = this.$listeners;
      return rest;
    },
    results() {
      const { searchValue, options } = this;
      if (!searchValue) return [];
      return options.slice(0, this.maxResult);
    },
    debouncedSearch() {
      return debounce(this.handleSearch, this.throttle);
    },
    hasNoResults() {
      if (!this.searchValue || this.pending) return false;
      return (
        this.searchValue &&
        this.searchValue.length >= this.minSearch &&
        !this.options.length
      );
    },
    showHelper() {
      return !this.options.length && this.searchValue.length < this.minSearch;
    },
  },
  watch: {
    value(val = "") {
      this.searchValue = val;
    },
  },
  methods: {
    close() {
      this.isOpen = false;
      if (this.value && this.searchValue !== this.value) {
        this.searchValue = this.value;
      }
      document.removeEventListener("click", this.clickOutside);
    },
    open() {
      if (this.isOpen) return;
      this.isOpen = true;
      document.addEventListener("click", this.clickOutside);
    },
    /**
     * Обработчик клика вне пределов автокомплита,
     * чтобы закрыть дропдаун
     */
    clickOutside(e) {
      const isInside = this.$el.contains(e.target);
      if (!isInside) {
        this.close();
        return;
      }
      if (
        e.target.closest(".autocomplete__content") ||
        !e.target.closest(".autocomplete")
      ) {
        this.close();
      }
    },
    setResult(data) {
      this.$emit("input", data.label);
      this.$emit("onSelect", data);
      this.searchValue = data.label;
      this.isOpen = false;
    },
    handleClear() {
      if (this.pending) return;
      this.searchValue = "";
      this.$emit("input", "");
      this.$emit("onSelect");
      // this.list = [];
    },
    /** Расфокус на всем компоненте */
    handleBlurContent() {
      this.close();
    },
    /** Фокус на инпуте с автокомплитом */
    handleFocus() {
      if (!this.isOpen) {
        document.removeEventListener("click", this.clickOutside, true);
        this.open();
      }
    },
    handleInput(val) {
      if (val) {
        if (!this.isOpen) {
          this.open();
        }
      } else {
        this.handleClear();
      }
      this.$emit("input", val);
      if (val && val.length >= this.minSearch) {
        this.debouncedSearch(val);
      }
    },
    handleSearch(query) {
      this.$emit("onSearch", query);
    },
    /***
     * Обрабатываем нажатие на энтер в инпуте.
     * выбираем первый результат, если есть
     */
    handleSelectFirstResult() {
      const { options } = this;
      if (options.length) {
        this.setResult(options[0]);
      }
    },

    handleClick() {
      if (!this.searchValue) {
        this.handleFocus();
      }
      this.handleClear();
    },
  },
};
</script>

<style lang="less" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s linear;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.autocomplete {
  position: relative;
  color: #000;

  &__append {
    & > div {
      padding: 11px;
      color: #c0c0c0;
    }
  }

  &__text {
    padding: 11px;
  }

  &__spinner {
    position: absolute;
    width: 30px;
    height: 30px;
    pointer-events: none;
    color: fade(@primary-color, 60%);

    &.with-chevron {
      right: 1px;
    }
  }

  &__clear {
    background-color: transparent;
    border: 0;
    padding: 0;
    outline: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: #c0c0c0;
    transition: color 0.3s;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &:hover {
      color: #c0c0c0;
    }
  }

  ::v-deep .base-input__control {
    padding-right: 40px;
  }

  &__content {
    position: absolute;
    top: @input-height;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 0;
    border-radius: 4px;
    z-index: 100;
    .fz(12);
    line-height: 16 / 12;
    border: 1px solid @input-border-color;
    border-top: 0;

    overflow-y: auto;
    max-height: 400px;
  }

  &__item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #c0c0c0;
    }

    & + & {
      border-top: 1px solid #c0c0c0;
    }
  }

  &__select {
    position: absolute;
    // width: @filter-height;
    // height: @filter-height - 2px;
    right: -5px;
    top: 50%;
    margin-top: -19px;
    padding: 4px 8px;
    text-align: center;
    transition: transform 0.2s ease;

    &.is-open {
      transform: rotate(180deg);
    }

    &::before,
    &::after {
      content: "";
      width: 10px;
      height: 2px;
      background-color: green;
      position: absolute;
      top: 59%;
      left: 0;
      transform: rotate(45deg);
      margin-top: -2.5px;
      transition: 0.4s;
    }

    &.icon-disabled {
      &::before,
      &::after {
        background-color: gray;
      }
    }

    &::before {
      margin-top: -2px;
      left: 13px;
    }

    &::after {
      width: 10px;
      left: 19px;
      transform: rotate(-45deg);
    }

    &.selected {
      &::before,
      &::after {
        left: 88%;
        top: 55%;
        width: 12px;
        margin: -2.5px 0 0 -20px;
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  &--theme-reg &__content {
    background: @bg-dark;
    border-color: @reg-input-border-color;
    color: @reg-input-text-color;
    top: @reg-input-height;
  }

  &--theme-reg &__item {
    .fz(16);
    border-top-color: fade(@reg-input-border-color, 50%);
    &:hover {
      background: @reg-input-border-color;
    }
  }
}
</style>
